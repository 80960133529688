<template>
   <Fix />
</template>

<script>
  import { defineAsyncComponent } from 'vue'
export default {
    name: 'Fix Page',
    components: {
    Fix: defineAsyncComponent( () => import('@/components/Fix.vue')),
      },

}
</script>